<template>
    <div class="d-flex">
        <label for="time-scale">{{ label }}&nbsp;:&nbsp;</label>
        <div v-if="enabled" class="d-flex">
            <vs-checkbox v-model="activeComponent" @input="onInput">
            </vs-checkbox>
        </div>

        <div v-else class="d-flex">
            <span>
                {{ active ? "Si" : "No" }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckboxSelector",
    model: {
        prop: "active",
        event: "change"
    },
    props: {
        active: {
            default: () => false,
            type: Boolean,
            require: true
        },
        label: {
            default: () => "",
            type: String,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        activeComponent: false
    }),
    watch: {
        active(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.active);
    },
    methods: {
        setInput(value) {
            this.activeComponent = value;
        },
        onInput() {
            this.$emit("change", this.activeComponent);
        }
    }
};
</script>
