<template>
    <div class="w-100 h-100 position-relative">
        <div v-if="enabled">
            <span v-if="isLoading"> Cargando...</span>
            <div v-else class="d-flex">
                <vs-select
                    v-model="options"
                    filter
                    multiple
                    collapse-chips
                    label="Roles"
                    :state="stateInputDark"
                    class="w-100 mw-100 border-custom"
                    @input="onInput"
                >
                    <vs-option
                        v-for="(item, index) in optionsBase"
                        :key="index"
                        :label="item.name"
                        :value="item.slug"
                    >
                        {{ item.name }}
                    </vs-option>
                </vs-select>

                <vs-button circle icon floating @click="reloadItems(true)">
                    <reload-icon />
                </vs-button>
            </div>
        </div>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> Roles</label>
            </div>

            <div class="d-flex flex-wrap px-2">
                <div
                    v-for="(item, index) in itemsCurrent"
                    :key="index"
                    class="bg-primary rounded mx-1 mt-1 px-1"
                    :label="item"
                >
                    <small>{{ item.name }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";

export default {
    name: "RoleSelector",
    components: { ReloadIcon },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        },
        defaultFilters: {
            default: () => ({}),
            type: Object,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        options: [],
        optionsBase: [],
        isLoading: false
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    async mounted() {
        this.setInput(this.itemsCurrent);
        if (this.fill) {
            this.reloadItems(true);
        }
    },
    methods: {
        ...mapActions("roles", ["listAllRoles"]),
        async reloadItems(required = true) {
            this.isLoading = true;
            try {
                const optionsBase = await this.listAllRoles({
                    required,
                    ...this.defaultFilters
                });
                this.optionsBase = [...optionsBase];
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        onInput(value) {
            const complete = this.optionsBase.filter((item) =>
                value.includes(item.slug)
            );
            this.$emit("change", complete);
        },
        setInput(values) {
            const aux = values.every((item) =>
                this.optionsBase.some((item2) => item2.slug === item.slug)
            );
            if (!aux) {
                this.optionsBase = [...this.optionsBase, ...values].filter(
                    (value, index, self) =>
                        self.findIndex((item) => item.slug === value.slug) ===
                        index
                );
            }
            this.options = values.map((item) => item.slug);
            this.isLoading = false;
        }
    }
};
</script>
