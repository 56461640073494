<template>
    <div class="w-100 h-100 position-relative">
        <div v-if="enabled" class="d-flex">
            <span v-if="isLoading"> Cargando...</span>
            <div v-else class="d-flex">
                <vs-select
                    v-model="options"
                    filter
                    multiple
                    collapse-chips
                    label="Almacenes actuales"
                    :state="stateInputDark"
                    class="w-100 mw-100 border-custom"
                    @input="onInput"
                >
                    <vs-option
                        v-for="(item, index) in optionsBase"
                        :key="index"
                        :label="item.name"
                        :value="`${item.merchantType}:${item.merchantId}`"
                    >
                        {{ item.name }}
                    </vs-option>
                </vs-select>
            </div>
        </div>
        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> Comercios</label>
            </div>

            <div class="d-flex flex-wrap px-2">
                <div
                    v-for="(item, index) in itemsCurrent"
                    :key="index"
                    class="bg-secondary rounded mx-1 mt-1"
                    :label="`${item.merchantType}:${item.merchantId}`"
                >
                    <div class="bg-primary rounded px-1 text-capitalize py-1">
                        <span>
                            {{
                                item.merchantType | merchantInfo("icon", "type")
                            }}
                            {{ item.name }}
                        </span>
                    </div>
                    <div class="p-1">
                        <span>
                            {{ item.roleName }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    name: "MerchantsRoleSelector",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        fill: {
            default: () => false,
            type: Boolean,
            required: false
        },
        itemsCurrent: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    emits: ["change"],
    data: () => ({
        options: [],
        isLoading: false,
        optionsBase: []
    }),
    computed: {
        ...mapState("commons", ["merchantsOwn"]),
        ...mapGetters("control", ["stateInputDark", "backgroundColor"])
    },
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    async mounted() {
        this.setInput(this.itemsCurrent);
        if (this.fill) {
            await this.reloadItems(false);
        }
    },
    methods: {
        ...mapActions("commons", ["listOwnMerchants"]),
        async reloadItems(required) {
            this.isLoading = true;
            try {
                const optionsBase = await this.listOwnMerchants({ required });
                this.optionsBase = [...optionsBase];
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        merchantObjectToString({ merchantId, merchantType }) {
            return `${merchantType}:${merchantId}`;
        },
        merchantStringToObject(merchantString) {
            const [merchantType, merchantId] = merchantString.split(":");
            return { merchantType, merchantId };
        },
        onInput(values) {
            const complete = this.optionsBase.filter((item) =>
                values.some((item2) => {
                    const { merchantId, merchantType } =
                        this.merchantStringToObject(item2);
                    return (
                        merchantId === item.merchantId &&
                        merchantType === item.merchantType
                    );
                })
            );
            this.$emit("change", complete);
        },
        setInput(values) {
            const aux = values.every((item) =>
                this.merchantsOwn.some(
                    (item2) =>
                        item2.merchantId === item.merchantId &&
                        item2.merchantType === item.merchantType
                )
            );
            if (!aux) {
                this.optionsBase = [...this.optionsBase, ...values].filter(
                    (value, index, self) =>
                        self.findIndex(
                            (item) =>
                                value.merchantId === item.merchantId &&
                                value.merchantType === item.merchantType
                        ) === index
                );
            }
            this.options = values.map(
                (item) => `${item.merchantType}:${item.merchantId}`
            );
        }
    }
};
</script>
